import React, { Component } from 'react';
import { screenset } from 'appdir/components/general/Gigya/utils';
import { AuthContext } from 'appdir/components/general/Gigya/context';
import op from 'object-path';

import TermsOfService from './elements/TermsOfService';
import PrivacyPolicy from './elements/PrivacyPolicy';
import InputBox from './elements/InputBox';

class Registration extends Component {
	constructor(props) {
		super(props);

		//let register_classname = gigya-conditional:className="viewport.width < 550 ?gigya-screen v2 portrait mobile:viewport.width < 920 ?gigya-screen v2 portrait:"

		//gigya-conditional:value="screenset.width < 430?100%"
		this.register_social_width = '100%';

		//gigya-conditional:value="screenset.width < 430?auto:"
		this.register_paging_button = 'newArrows';

		//gigya-conditional:value="screenset.width < 430?2:"
		this.register_columns = '';

		//gigya-conditional:show-password-strength="viewport.width < 920?up"
		this.strengthInfo = 'left';

		//data-caption="screenset.translations['GIGYA_REGISTER_SCREEN_CAPTION']"
		//value="screenset.translations['SUBMIT_7667737432077126_VALUE']"
	}

	render() {
		let enabledProviders = op.get(this, 'context.socialProviders', '');
		let screen = op.get(this, 'context.screen', '');

		logger.log('[Gigya] [Registration] render - context:%o', this.context);

		if (this.context.windowSize == 'tablet' || this.context.windowSize == 'mobile') {
			this.register_classname = 'local-screen gigya-screen v2 portrait';
		} else {
			this.register_classname = 'local-screen gigya-screen v2 landscape';
		}

		let socialDisplay = {};
		if (!screen.social) {
			socialDisplay = { display: 'none' };
		}

		//logger.log('[Gigya] [Registration] render - display:%o', socialDisplay);

		return (
			<div
				id="gigya-register-screen"
				data-width="auto"
				className={this.register_classname}
				data-on-success-screen="gigya-complete-registration-screen"
				data-caption="">
				<form className="gigya-register-form" id="gigya-register-form">
					<div className="gigya-layout-row with-divider">
						<div
							id="register-social-login"
							className="gigya-layout-cell responsive with-social-login"
							style={socialDisplay}>
							<div className="gigya-layout-row" style={{ height: '100%' }}>
								<h2
									className="gigya-composite-control gigya-composite-control-header"
									style={{ display: 'block' }}
									data-translation-key="HEADER_REGISTER_SOCIAL_LABEL"
								/>
								<div
									className="gigya-composite-control gigya-composite-control-social-login"
									style={{ display: 'block' }}>
									<div className="gigya-social-login">
										<param name="tabIndex" value="0" />
										<param name="version" value="2" />
										<param name="width" value={this.register_social_width} />
										<param name="height" value="50" />
										<param name="enabledProviders" value={enabledProviders} />
										<param name="loginMode" value="standard" />
										<param name="buttonsStyle" value="fullLogoColored" />
										<param name="pagingButtonStyle" value={this.register_paging_button} />
										<param name="buttonSize" value="45" />
										<param name="columns" value={this.register_columns} />
										<param name="showWhatsThis" value="false" />
										<param name="showTermsLink" value="false" />
										<param name="hideGigyaLink" value="true" />
										<param name="_pluginCenterFix" value="true" />
									</div>
								</div>
							</div>
							<div className="gigya-layout-row google-terms-l">
								This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
							</div>
						</div>

						<div className="gigya-layout-cell responsive with-divider" style={socialDisplay}>
							<label
								className="gigya-divider-content gigya-label-text gigya-composite-control gigya-composite-control-label"
								data-translation-key="LABEL_OR"
							/>
						</div>

						<div id="register-site-login" className="gigya-layout-cell responsive with-site-login">
							<div className="gigya-layout-row">
								<h2
									className="gigya-composite-control gigya-composite-control-header"
									data-translation-key="HEADER_REGISTER_LABEL"
								/>
								<div className="gigya-layout-row">
									<div className="gigya-composite-control gigya-composite-control-textbox">
										<InputBox
											data={{
												type: 'email',
												name: 'email',
												key: 'TEXTBOX_EMAIL',
												showValid: 'true',
												class: 'gigya-input-text',
												tabIndex: '0',
											}}
										/>
									</div>
								</div>
							</div>
							<div id="password-row" className="gigya-layout-row with-two-inputs">
								<div className="gigya-layout-cell">
									<div
										className="gigya-composite-control gigya-composite-control-password eyeDiv7"
										style={{ display: 'block' }}>
										<InputBox
											data={{
												type: 'password',
												name: 'password',
												key: 'TEXTBOX_PASSWORD',
												showValid: 'true',
												showStrength: this.strengthInfo,
												class: 'gigya-input-password',
												tabIndex: '0',
											}}
										/>
									</div>
								</div>
								<div className="gigya-layout-cell">
									<div className="gigya-composite-control gigya-composite-control-password eyeDiv8">
										<InputBox
											data={{
												type: 'password',
												name: 'passwordRetype',
												key: 'TEXTBOX_PASSWORD_CONFIRM',
												showValid: 'true',
												showStrength: '',
												class: 'gigya-input-password',
												tabIndex: '0',
											}}
										/>
									</div>
								</div>
								<div className="gigya-clear" />
								<div className="gigya-clear" />
							</div>
							<div
								className="gigya-composite-control gigya-spacer"
								data-units="1"
								style={{ height: '10px' }}
							/>

							<div className="gigya-layout-row">
								<div
									style={{ margin: '16px 0px' }}
									className="gigya-composite-control gigya-composite-control-subscription-widget">
									<i>{screenset.translations['SUBS_NEWSLETTER_LABEL']}</i>
								</div>

								<PrivacyPolicy />

								<TermsOfService />
							</div>

							<div className="gigya-layout-row" >
								<div className="gigya-composite-control gigya-composite-control-captcha-widget gigya-unsupported gigya-v2">
									<div className="gigya-captcha-wrapper gigya-error-display" data-error-flags="captchaNeeded" data-bound-to="gigya-register-form">
										<div className="gigya-captcha">
											<param name="theme" value="light"/>
											<param name="badge" value="inline"/>
											<param name="type" value="image"/>
											<param name="size" value="normal"/>
											<param name="mode" value="visible"/>
										</div> <span className="gigya-error-msg" data-bound-to="captchaText"></span>
									</div>
								</div>

							</div>
							<div className="gigya-layout-row">
								<div className="gigya-composite-control gigya-composite-control-submit">
									<input
										type="submit"
										className="gigya-input-submit"
										tabIndex="0"
										value="screenset.translations['BUTTON_SUBMIT_VALUE']"
									/>
								</div>
								<div
									className="gigya-error-display gigya-composite-control gigya-composite-control-form-error"
									data-bound-to="gigya-register-form">
									<div
										className="gigya-error-msg gigya-form-error-msg"
										data-bound-to="gigya-register-form"
									/>
								</div>
							</div>
							<div
								className="gigya-composite-control gigya-spacer"
								data-units="1"
								style={{ height: '10px' }}
							/>
							<div className="gigya-composite-control gigya-spacer-divider" data-units="1" />
							<div className="gigya-layout-row">
								<a
									data-switch-screen="gigya-login-screen"
									className="gigya-composite-control gigya-composite-control-link"
									data-translation-key="LINK_HAVE_ACCOUNT_LABEL"
								/>
							</div>
							<div className="gigya-layout-row google-terms-p">
								This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
							</div>						
						</div>
					</div>
				</form>
			</div>
		);
	}
}

Registration.contextType = AuthContext;
export default Registration;

/**
Same as Login screen
no message when using this
     					<div className="gigya-composite-control gigya-composite-control-captcha-widget v1 gigya-unsupported gigya-v2">
							<div
								className="gigya-captcha-wrapper gigya-error-display"
								data-error-flags="captchaNeeded"
								data-bound-to="gigya-login-form">
								<div className="gigya-captcha">
									<param name="theme" value="light" />
									<param name="type" value="image" />
									<param name="size" value="normal" />
								</div>
								<span className="gigya-error-msg" data-bound-to="captchaText" />
							</div>
						</div>


With settings invisible captcha
if "gigya-invisible" not included see checkbox and "To register, confirm you are not a robot"
                                <div className="gigya-composite-control gigya-composite-control-captcha-widget gigya-unsupported gigya-invisible">
                                    <div className="gigya-captcha-wrapper">
                                        <div className="gigya-captcha">
                                            <param name="theme" value="light" />
                                            <param name="badge" value="inline" />
                                            <param name="type" value="image" />
                                            <param name="size" value="normal" />
                                            <param name="mode" value="invisible" />
                                        </div>{' '}
                                        <span className="gigya-error-msg" data-bound-to="captchaText"></span>{' '}
                                    </div>
                                </div>
                     

so this version works to display checkbox and require before submit
but isn't affected by RVBA rules, just appears all the time
								<div className="gigya-composite-control gigya-composite-control-captcha-widget gigya-unsupported">
									<div className="gigya-captcha-wrapper" data-error-flags="captchaNeeded" data-bound-to="gigya-register-form">
										<div className="gigya-captcha">
											<param name="theme" value="light"/>
											<param name="badge" value="inline"/>
											<param name="type" value="image"/>
											<param name="size" value="normal"/>
											<param name="mode" value="visible"/>
										</div> <span className="gigya-error-msg" data-bound-to="captchaText"></span>
									</div>
								</div>
 */






/**********************
others which may work without latest keys?
*/



/**
copied from screenset builder
<div class="gigya-composite-control gigya-composite-control-captcha-widget gigya-v2 gigya-unsupported">
	<div class="gigya-captcha-wrapper gigya-error-display" data-error-flags="captchaNeeded"
		data-bound-to="gigya-reset-password-form" data-screenset-element-id="__gig_template_element_48_1722354492459"
		data-screenset-element-id-publish="false" data-screenset-roles="template" aria-atomic="true">
		<div class="gigya-captcha gigya-reset" data-screenset-element-id="__gig_template_element_54_1722354492459"
			data-screenset-element-id-publish="false" data-screenset-roles="template">
			<param name="theme" value="light"/>
			<param name="badge" value="bottomright"/>
			<param name="type" value="image"/>
			<param name="size" value="normal"/>
			<param name="mode" value="visible"/>
		</div> <span class="gigya-error-msg" data-bound-to="captchaText"
			data-screenset-element-id="__gig_template_element_51_1722354492459"
			data-screenset-element-id-publish="false" data-screenset-roles="template" aria-atomic="true"></span>
	</div>
</div>
 */

/**
working with v2 and v3 captcha and  RBA in settings
uses v2 checkbox and v3 keys

<div className="gigya-composite-control gigya-composite-control-captcha-widget gigya-unsupported gigya-v2">
	<div className="gigya-captcha-wrapper gigya-error-display" data-error-flags="captchaNeeded" data-bound-to="gigya-register-form">
		<div className="gigya-captcha">
			<param name="theme" value="light"/>
			<param name="badge" value="bottomright"/>
			<param name="type" value="image"/>
			<param name="size" value="compact"/>
			<param name="mode" value="visible"/>
		</div> <span className="gigya-error-msg" data-bound-to="captchaText"></span>
	</div>
</div>
*/

/**
working with v2 invisible recaptcha
uses v2 invisible keys

<div className="gigya-composite-control gigya-composite-control-captcha-widget gigya-unsupported gigya-invisible">
	<div className="gigya-captcha-wrapper">
		<div className="gigya-captcha">
			<param name="theme" value="light" />
			<param name="badge" value="inline" />
			<param name="type" value="image" />
			<param name="size" value="normal" />
			<param name="mode" value="invisible" />
		</div>{' '}
		<span className="gigya-error-msg" data-bound-to="captchaText"></span>{' '}
	</div>
</div>

center vertucally, containing div of challenge
top: 50%;
transform: translateY(-50%);
 */

/**
not sure with regular v2 (I am not a robot)
uses v2 invisible keys

<div className="gigya-composite-control gigya-composite-control-captcha-widget gigya-unsupported gigya-invisible">
										<div className="gigya-captcha-wrapper">
											<div className="gigya-captcha">
												<param name="theme" value="light"/>
												<param name="badge" value="inline"/>
												<param name="type" value="image"/>
												<param name="size" value="normal"/>
												<param name="mode" value="invisible"/>
											</div> <span class="gigya-error-msg" data-bound-to="captchaText"></span> </div>
									</div>
 */

/**

<div className="gigya-composite-control gigya-composite-control-captcha-widget v1 gigya-v1">
										<div className="gigya-captcha-wrapper gigya-error-display" data-error-flags="captchaNeeded" data-bound-to="gigya-register-form">
											<div className="gigya-captcha">
												<param name="theme" value="white"/>
												<param name="mode" value="visible"/>
											</div>
											<span className="gigya-error-msg" data-bound-to="captchaText"></span>
										</div>
									</div>


								<div className="gigya-composite-control gigya-composite-control-captcha-widget gigya-invisible">
										<div className="gigya-captcha-wrapper">
											<div className="gigya-captcha">
												<param name="theme" value="light" />
												<param name="badge" value="inline" />
												<param name="type" value="image" />
												<param name="size" value="normal" />
											</div>
											<span className="gigya-error-msg" data-bound-to="captchaText" />
										</div>
									</div>
 */
