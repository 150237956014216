/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { useState, useEffect } from 'react';
import { FormInput, FormTextarea, FormRadioButton, FormCheckBox } from 'appdir/components/common-ui/FormFields';
import Button from 'appdir/components/common-ui/Button';
import WimLink from 'appdir/components/general/WimLink';

/**
 * -----------------------------------------------------------------------------
 * Functional Component: const ApplicantAccreditationFormContd = (props) => {
 * -----------------------------------------------------------------------------
 */
const ApplicantAccreditationFormContd = props => {
	logger.log('[ApplicantAccreditationFormContd] props:%o', props);
	let initialState = {
		showDatesTextBox: props.formProps.values.attendChampionshipsSingleDays,
	};

	const [state, setState] = useState({ ...initialState });

	const checkDatesDisplay = () => {
		let checkBox = document.getElementById('attendChampionshipsSingleDays');

		let { showDatesTextBox } = state;
		let checkboxStatus = checkBox.checked;
		if (checkboxStatus !== showDatesTextBox) {
			setState({
				showDatesTextBox: checkboxStatus,
			});
		}

		if (!checkboxStatus) {
			props.formProps.setFieldValue('attendDays', '');
		}
	};

	const renderOrganisationInputs = count => {
		return (
			<div className="two-col margin-col clear-two clear-four" key={`organisationInputs-${count}`}>
				<span className="two-col no-margin left">
					<FormInput label="Organisation Name" name={`organisationName${count}`} type="text" />
				</span>
				<span className="two-col">
					<FormInput label="Organisation URL" name={`organisationURL${count}`} type="text" />
				</span>
			</div>
		);
	};

	const getOrganisationInputs = () => {
		// logger.log('[ApplicantAccreditationFormContd] getOrganisationInputs');
		let inputs = [];

		for (var i = 0; i < props.orgCount; i++) {
			inputs.push(renderOrganisationInputs(i));
		}

		return inputs;
	};

	const updateOrgCount = () => {
		let count = props.orgCount + 1;
		props.updateOrgCount(count);
	};

	let { values } = props.formProps;
	return (
		<div className="column-layout">
			<div className="four-col page-title">
				<div className="title-header">APPLICANT ACCREDITATION CONTINUED</div>
			</div>
			<div className="two-col margin-col clear-two clear-four">
				Please list all other organisations/publications/websites that will be represented by the applicant.
			</div>
			<div className="two-col margin-col clear-two clear-four smaller">
				<i>
					Please note that Broadcast outlets are covered by special terms and conditions. If you are intending
					to report for such outlets we will need further clarification on the category of coverage you wish
					to pursue. Websites that are not part of your primary organisation will also require approval and
					will be subject to the Accreditation Terms and Conditions, as in previous years, and we may
					request that a link is made to our website if approval is granted.
				</i>
			</div>

			{getOrganisationInputs()}

			<div className="two-col margin-col clear-two clear-four">
				<span className="hospitality-btns no-margin right left">
					<Button className="myw" onClick={updateOrgCount}>
						+ Add Organisation
					</Button>
				</span>
			</div>
			{values.photographer == true ? (
				<>
					<div className="two-col margin-col clear-two clear-four required">
						Who will hold picture copyright?
					</div>
					<div className="two-col margin-col clear-two clear-four">
						<span className="two-col no-margin left">
							<FormRadioButton
								value="Publication"
								label="Publication"
								name="pictureCopyright"
								required
								showerror="true">
								Publication
							</FormRadioButton>
						</span>
						<span className="two-col no-margin left">
							<FormRadioButton
								value="Photographer"
								label="Photographer"
								name="pictureCopyright"
								required
								showerror="true">
								Photographer
							</FormRadioButton>
						</span>
						{typeof props.formProps.errors.pictureCopyright === 'string' ? (
							<div className="error">{props.formProps.errors.pictureCopyright}</div>
						) : null}
					</div>
				</>
			) : null}
			{values.type == 'regionalNewspaper' ? (
				<>
					<div className="two-col margin-col clear-two clear-four">
						Are you interested in following the progress of any particular player(s) from your local area?
					</div>
					<div className="two-col margin-col clear-two clear-four">
						<FormTextarea
							label=""
							name="playerInterest"
							type="textarea"
							placeholder="If yes, please name the players."
						/>
					</div>
				</>
			) : null}
			<div className="two-col margin-col clear-two clear-four media-form-header">Events &amp; Attendance</div>
			<div className="two-col margin-col clear-two clear-four">
				Please specify when you wish to attend The Championships. Select all that apply.
			</div>
			<div className="two-col margin-col clear-two clear-four">
				<div className="four-col no-margin left right top">
					<span>
						{/* <FormCheckBox name="attendChampionshipsQualifying" value="Qualifying Tournament"> */}
						<FormCheckBox name="attendChampionshipsQualifying" id="attendChampionshipsQualifying">
							Qualifying Tournament
						</FormCheckBox>
					</span>
				</div>
				<div className="four-col no-margin left right top">
					<span>
						{/* <FormCheckBox name="attendChampionshipsEntire" value="Entire Championships"> */}
						<FormCheckBox name="attendChampionshipsEntire" id="attendChampionshipsEntire">
							The Championships (full two weeks)
						</FormCheckBox>
					</span>
				</div>
				<div className="four-col no-margin left right top">
					<span>
						{/* <FormCheckBox name="attendChampionshipsWeek1" value="Full Week 1"> */}
						<FormCheckBox name="attendChampionshipsWeek1" id="attendChampionshipsWeek1">
							Full Week 1
						</FormCheckBox>
					</span>
				</div>
				<div className="four-col no-margin left right top">
					<span>
						{/* <FormCheckBox name="attendChampionshipsSingleDays" value="Single day or days"> */}
						<FormCheckBox
							onClick={checkDatesDisplay}
							name="attendChampionshipsSingleDays"
							id="attendChampionshipsSingleDays">
							Single day or days
						</FormCheckBox>
					</span>
				</div>
				{(state.showDatesTextBox || props.formProps.values.attendChampionshipsSingleDays) && (
					<div className="four-col no-margin left right top">
						<FormTextarea
							label=""
							name="attendDays"
							type="textarea"
							placeholder="Please enter the dates you wish to attend."
						/>
					</div>
				)}
			</div>
			{/* <div className="two-col margin-col clear-two clear-four">
                Before submitting your application, please confirm that you acknowledge and
                accept the <WimLink to="">Data Privacy Notice</WimLink> on behalf of this Applicant.
            </div> */}
			<div className="two-col margin-col clear-two clear-four">
				<span className="hospitality-btns">
					<Button className="media-btn" onClick={props.goBack}>
						&lt; Back
					</Button>
					<Button
						className={`media-btn long solid${props.formProps.isSubmitting ? ' disabled' : ''}`}
						onClick={props.formProps.submitForm}>
						Save &amp; Continue {'>'}
					</Button>
				</span>
			</div>
		</div>
	);
};

export default ApplicantAccreditationFormContd;
